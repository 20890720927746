module.exports = {
  'Details': 'विवरण',
  '切换': '切换',
  'GO': 'जाना',
  'Join Now': 'अब शामिल हों',
  '暂未开放': 'पंजीकरण अभी खुला नहीं है, कृपया अधिसूचना की प्रतीक्षा करें।',
  'Balance': 'बैलेंस',
  'View': 'देखना',
  'ACTIVATION': 'सक्रियण',
  'CONFIRM': 'पुष्टि करना',
  'Tell': 'कहना',
  'Tips': 'युक्तियाँ',
  'Activation code': 'सक्रियण कोड',
  'Please enter your phone number': 'कृपया अपना फ़ोन नंबर दर्ज करें',
  'Please enter the activation code': 'कृपया सक्रियण कोड दर्ज करें',
  'Activated to log in': 'लॉग इन करने के लिए सक्रिय',
  'Not activated Go to activate': 'सक्रिय नहीं सक्रिय करने के लिए जाओ',
  'Red envelope rain': 'लाल लिफाफा बारिश',
  'Balance details': 'शेष विवरण',
  'BALANCE': 'संतुलन',
  '您已经参与过了': 'आप शामिल रहे हैं',
  '提示': 'टिप',
  '确定': 'निर्धारित',
  '绑定成功': 'बाइंडिंग सफल',
  '当前红包雨未开启': 'लाल लिफाफों की वर्तमान बारिश शुरू नहीं हुई है',
  '您已参与过本场红包雨': 'आप इस लाल लिफाफे की बारिश में शामिल रहे हैं',
  '谢谢惠顾': 'संरक्षण देने के लिए धन्यवाद',
  '恭喜中奖': 'जीत के लिए बधाई',
  '未登录': 'लॉगिन समाप्त',
  '加载中': 'लोड हो रहा है',
  'Verification Code': 'सत्यापन कोड',
  'Please enter the verification code': 'कृपया सत्यापन कोड दर्ज करें',
  'Name': 'नाम',
  'Please enter your name': 'कृपया अपना नाम दर्ज करें',
  'SUBMIT': 'सबमिट करें',
  'Please enter your ID number': 'कृपया अपना आधार संख्या दर्ज करें',
  'ID number': 'आधार संख्या',
  'Please enter your IFSC': 'कृपया अपना आईएफएससी दर्ज करें',
  'IFSC': 'आईएफएससी',
  'Please enter your Account Number': 'कृपया अपना आधार नंबर दर्ज करें',
  'Account Number': 'खाता संख्या',
  '已提交申请': 'आवेदन जमा',
  'TEL': 'दूरभाष',
  'SEND': 'भेजें',
  'REAL NAME': 'असली नाम',
  '加载中...': 'लोड हो रहा है...',
  '发送成功': 'सफलतापूर्वक भेजा जा रहा है',
  'Withdrawal': 'निकासी',
  'Not yet registered': 'कृपया पहले वास्तविक नाम प्रमाणीकरण पूरा करें',
  'Please enter the withdrawal amount': 'कृपया निकासी राशि दर्ज करें',
  'Withdrawal Amount': 'आहरण राशि',
  'ID card photo': 'आधार कार्ड फोटो',
  'Please enter your ID card photo': 'कृपया अपना आधार कार्ड फोटो दर्ज करें',
  'front': 'सामने वाला',
  'back': 'पीछे वाला',

}