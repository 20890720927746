import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import { Locale } from 'vant'
import router from '../../router'
import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN';
import enLocale from './en'
import zhLocale from './zh'
const messages = {
  en: {
    ...enUS,
    ...enLocale
  },
  zh: {
    ...zhCN,
    ...zhLocale
  }
}
// console.log("-----", window.location.href)

// Get the value of the 's' parameter from the URL
// Get the value of the 's' parameter from the URL

function getQueryParam(paramName) {
  // 创建URL对象
  const url = new URL(window.location.href);
  console.log('url', url.hash)
  const hash = url.hash;
  // 使用URLSearchParams获取查询参数
  const hashWithoutHash = hash.replace('#/?', '');
  console.log(hashWithoutHash)
  // 将哈希字符串转换为参数对象
  const params = new URLSearchParams(hashWithoutHash);
  // 返回指定参数的值
  return params.get(paramName);
}

// 假设当前URL是 http://localhost:8080/#/?s=depuzGK
const sValue = getQueryParam('s'); // 返回 'depuzGK'
console.log('sValue', sValue);

//打印路由url

// https://kazupon.github.io/vue-i18n/zh/api/#%E6%9E%84%E9%80%A0%E5%87%BD%E6%95%B0%E9%80%89%E9%A1%B9
const i18n = new VueI18n({
  locale: localStorage.getItem(`${sValue}lang`) || 'en',//默认中文 l('lang') || 'zh',
  messages,
  silentTranslationWarn: false
})



// 更新vant组件库本身的语言变化，支持国际化
function vantLocales(lang) {
  localStorage.setItem(`${sValue}lang`, lang)
  if (lang == 'en') {
    Locale.use(lang, enUS)
  } else {
    Locale.use(lang, zhCN)
  }
}

export { i18n, vantLocales }