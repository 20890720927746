var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-btn"},[_c('div',{staticClass:"btn_box"},[_c('button',{staticClass:"anBtn",class:{ auto: _vm.auto },style:('background: ' +
      _vm.bg +
      ';color:' +
      _vm.color +
      ';border-radius:' +
      _vm.radius +
      ';border:' +
      _vm.border +
      ';height:' +
      _vm.height +
      ';line-height:' +
      _vm.height +
      ';'),attrs:{"disabled":_vm.disabled},on:{"click":_vm.do_fun}},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }