import Vue from 'vue'
import router from 'vue-router'
import routes from './modules/pages'
import children from './modules/tabbar'
import components from './modules/components'
Vue.use(router)
const originalPush = router.prototype.replace
router.prototype.replace = function replace(location) {
  return originalPush.call(this, location).catch(err => err)
}

let routerPush = router.prototype.push;
router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

Object.keys(components).forEach(item => {
  Vue.component(item, components[item]);
})
const Router = new router({
  routes: [
    ...routes,
    {
      path: '/',
      component: () => import('@/views/tabbar'),
      children
    },
  ]
})
export default Router;