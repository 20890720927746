import { render, staticRenderFns } from "./h-red.vue?vue&type=template&id=a56fb654&scoped=true"
import script from "./h-red.vue?vue&type=script&lang=js"
export * from "./h-red.vue?vue&type=script&lang=js"
import style0 from "./h-red.vue?vue&type=style&index=0&id=a56fb654&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a56fb654",
  null
  
)

export default component.exports