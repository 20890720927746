module.exports = {
  'Details': 'Details',
  '切换': 'change',
  'GO': 'GO',
  'Join Now': 'Join Now',
  '暂未开放': 'Registration is not yet open, please wait for notification',
  'Balance': 'Balance',
  'View': ' Check',
  'ACTIVATION': 'ACTIVATION',
  'CONFIRM': 'CONFIRM',
  'Tell': 'Tell',
  'Tips': 'Tips',
  'Activation code': 'Activation code',
  'Please enter your phone number': 'Please enter your phone number',
  'Please enter the activation code': 'Please enter the activation code',
  'Activated to log in': 'Activated to log in',
  'Not activated Go to activate': 'Not activated Go to activate',
  'Red envelope rain': 'Red envelope rain',
  'Balance details': 'Balance details',
  'BALANCE': 'BALANCE',
  '您已经参与过了': 'You have already participated',
  '提示': 'Tip',
  '确定': 'CONFIRM',
  '绑定成功': 'Binding successful',
  '当前红包雨未开启': 'The current red envelope rain is not turned on',
  '您已参与过本场红包雨': 'You have participated in this red envelope rain',
  '谢谢惠顾': 'Thank you for your patronage',
  '恭喜中奖': 'Congratulations on winning the prize',
  '未登录': 'Login expired',
  '加载中': 'Loading',
  'Verification Code': 'Verification Code',
  'Please enter the verification code': 'Please enter the verification code',
  'Name': 'Name',
  'Please enter your name': 'Please enter your name',
  'SUBMIT': 'SUBMIT',
  'Please enter your ID number': 'Please enter your Aadhaar number',
  'ID number': 'Aadhaar number',
  'Please enter your IFSC': 'Please enter your IFSC',
  'IFSC': 'IFSC',
  'Please enter your Account Number': 'Please enter your Account Number',
  'Account Number': 'Account Number',
  '已提交申请': 'Application submitted',
  'TEL': 'TEL',
  'SEND': 'SEND',
  'REAL NAME': 'REAL NAME',
  '加载中...': 'Loading...',
  '发送成功': 'Sending successful',
  'Withdrawal': 'Withdrawal',
  'Not yet registered': 'Please complete real name authentication first',
  'Please enter the withdrawal amount': 'Please enter the withdrawal amount',
  'Withdrawal Amount': 'Withdrawal Amount',
  'ID card photo': 'Aadhaar card photo',
  'Please enter your ID card photo': 'Please enter your Aadhaar card photo',
  'front': 'front',
  'back': 'back',
}
