<template>
  <div class='indexbox'>
    <div class="top">
      <div class="top_img1">
        {{ info.title }}
      </div>
      <div class="top_img2" @click="change">
        <img v-if="getLang() == 'zh'" src="../../assets/img/yuyan@2x.png" alt="">
        <img v-else src="../../assets/img/yindu@2x.png" alt="">
      </div>
    </div>
    <div class="middle">
      <div class="robot">
        <img src="../../assets/img/yuansu@2x.png" alt="">
      </div>
      <div class="bth" @click="router('list', { con_type: 1, secret: secret })">
        {{ $t('Details') }}
        <img src="../../assets/img/more@2x.png" alt="">
      </div>
      <div class="text">
        <p>{{ truncateToSpace(info.brief_name, 0) }}<span> {{ tospace(info.brief_name) }}</span></p>
      </div>
      <div class="glasses1">
        <img src="../../assets/img/img1@2x.png" alt="">
      </div>
      <div class="glasses2">
        <img src="../../assets/img/img2@2x.png" alt="">
      </div>
    </div>
    <div class="bottom">
      <div class="left" @click="router('list', { con_type: 2, secret: secret })">
        <div class="one">{{ truncateToSpace(info.background_name, 0) }}</div>
        <div class="two">{{ tospace(info.background_name) }}</div>
        <div class="img">
          <img src="../../assets/img/tu1@2x.png" alt="">
        </div>
        <div class="bth">
          {{ $t('GO') }}
          <img src="../../assets/img/more@2x.png" alt="">
        </div>
      </div>
      <div class="right" @click="router('list', { con_type: 3, secret: secret })">
        <div class="one">{{ truncateToSpace(info.team_name, 0) }}</div>
        <div class="two">{{ tospace(info.team_name) }}</div>
        <div class="img">
          <img src="../../assets/img/tu2@2x.png" alt="">
        </div>
        <div class="bth">
          {{ $t('GO') }}
          <img src="../../assets/img/more@2x.png" alt="">
        </div>
      </div>
    </div>
    <div class="gif_box">
      <div class="bth" @click="router('list', { con_type: 4, secret: secret })">
        {{ $t('Join Now') }}
        <img src="../../assets/img/more@2x.png" alt="">
      </div>
      <div class="gif">
        {{ info.join_name }}
      </div>
      <div class="gif2">
        {{ info.join_name }}
      </div>
      <oi>
        <div class="rain" @click="opt()">
          <img src="../../assets/img/hb@3x.png" v-if="this.getLang() == 'zh'" alt="">
          <img src="../../assets/img/hb@2x.png" v-if="this.getLang() == 'en'" alt="">
        </div>
      </oi>
      <div class="register">
        <div class="left">
          <img src="../../assets/img/qianb@2x.png" alt="">
          {{ $t('Balance') }}
        </div>
        <div class="reigst" @click="Bopt()">
          {{ $t('View') }}
          <img src="../../assets/img/more@2x.png" alt="">
        </div>
      </div>
    </div>
    <van-popup v-model="show1" :close-on-click-overlay="false">
      <div class="popup">
        <div class="tops">
          {{ $t('Tips') }}
        </div>
        <div class="Tell">
          {{ $t('Tell') }}
        </div>
        <van-field v-model="phone" :border="false" type="digit" :placeholder="$t('Please enter your phone number')" />
        <div class="reigst" @click="get_login()">
          {{ $t('CONFIRM') }}
        </div>
        <div class="bottoms">
          {{ $t('Not activated Go to activate') }}
        </div>
      </div>
      <div class="close">
        <van-icon name="close" color="#fff" size="7vw" @click="show1 = false" />
      </div>
    </van-popup>
    <van-popup v-model="show2" :close-on-click-overlay="false">
      <div class="popup">
        <div class="tops">
          {{ $t('Tips') }}
        </div>
        <div class="Tell">
          {{ $t('Activation code') }}
        </div>
        <van-field v-model="code" :border="false" :placeholder="$t('Please enter the activation code')" />
        <div class="Tell">
          {{ $t('Tell') }}
        </div>
        <van-field v-model="phone" :border="false" type="digit" :placeholder="$t('Please enter your phone number')" />
        <div class="reigst" @click="register()">
          {{ $t('ACTIVATION') }}
        </div>
        <div class="bottoms">
          {{ $t('Activated to log in') }}
        </div>
      </div>
      <div class="close">
        <van-icon name="close" color="#fff" size="7vw" @click="show2 = false" />
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      secret: "",
      info: {},
      show1: false,
      show2: false,
      phone: "",
      code: "",
      venue: {}
    }
  },
  created() {
    if (this.$route.query.s) {
      this.secret = this.$route.query.s
    }
    this.get_info()
  },
  methods: {
    truncateToSpace(str, num) {
      if (str) return str.split(' ')[num];

    },
    tospace(src) {
      if (src) return src.substring(src.indexOf(' ') + 1)

    },
    Bopt() {
      if (this.local.get("token")) {
        this.router('marne')
      } else {
        this.show1 = true

      }
    },
    opt() {
      if (this.local.get("token")) {
        this.$get2({
          url: "/venue/info",
          success: (res) => {
            this.venue = res.data
            if (this.venue.venue_id == 0) {
              this.$toast(this.$t('当前红包雨未开启'))
              return
            }
            if (this.venue.part_in == 1) {
              this.$toast(this.$t('您已参与过本场红包雨'))
              return
            }


            this.router('rain', { id: this.venue.venue_id })
          },
          tip: () => { },
        })

      } else {
        this.show1 = true
      }
    },

    get_login() {
      if (!this.phone) return this.$toast(this.$t('Please enter your phone number'))
      this.$post({
        url: "/login",
        loading: true,
        noToken: true,
        data: {
          account: this.phone,
        },
        success: (res) => {
          this.local.set("token", res.data.token)
          this.show1 = false
          this.show2 = false
        },
        tip: () => {
          // this.toast({
          //   message: this.$t('Login successful'),
          //   onClose: () => {
          this.show1 = false
          this.show2 = true
          // },
          // })
        },
      })
    },
    register() {
      if (!this.code) return this.$toast(this.$t('Please enter the activation code'))
      if (!this.phone) return this.$toast(this.$t('Please enter your phone number'))
      this.$post({
        url: "/register",
        loading: true,
        noToken: true,
        data: {
          account: this.phone,
          active_code: this.code,
          secret: this.secret,
        },
        success: (res) => {
          this.local.set("token", res.data.token)
          this.toast({
            message: this.$t('绑定成功'),
            onClose: () => {
              this.show2 = false
            },
          })
        },
        tip: () => {

        },
      })
    },
    change() {
      if (this.getLang() == "zh") {
        this.changeLang("en")
      } else {
        this.changeLang("zh")
      }
      this.get_info()
    },
    open(url) {
      window.open(url)
    },
    get_info() {
      this.$post({
        url: "/site/home",
        data: {
          secret: this.secret
        },
        success: (res) => {
          this.info = res.data
          console.log(res)
        },
        tip: () => { },
      })
    },
  },
}
</script>
<style scoped lang="less">
.close {
  padding: 3vw;
  display: flex;
  justify-content: center;
}

.popup {
  width: 73vw;
  border-radius: 2vw;
  border: #641AF3 1px solid;
  background: #030A21;
  padding: 4vw;



  .reigst {
    width: 27vw;
    padding: 2.5vw 0;
    background: linear-gradient(55deg, #4609F5 0%, #6D1FF3 100%);
    box-shadow: 0 1 2vw 0 rgba(243, 112, 255, 0.17);
    border-radius: 1vw;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4vw;
    margin: 0 auto;
    margin-bottom: 3vw;
  }

  .bottoms {
    color: #661BF3;
    font-size: 3vw;
    text-align: center;
  }

  .tops {
    color: #fff;
    font-size: 4.5vw;
    font-weight: 550;
    text-align: center;
    padding: 0;
    margin-bottom: 2vw;
  }

  .Tell {
    color: #fff;
    font-size: 4vw;
    font-weight: 550;
    margin-bottom: 2vw;
  }

  /deep/.van-cell {
    padding: 2vw 3vw;
    border-radius: 1vw;
    background: #52467B;
    margin-bottom: 3vw;

    .van-field__control {
      color: #fff;
    }
  }
}

/deep/.van-popup {
  background: transparent;
}

.indexbox {
  width: 100vw;
  // height: calc(100vh - 12vw);
  height: 100vh;
  background-image: url("../../assets/img/bj@2x.png");
  background-size: 100% 100%;

  .top {
    display: flex;
    padding: 3vw 4vw;
    justify-content: space-between;
    align-items: center;

    .top_img1 {
      width: 83vw;
      height: 7vw;
      color: #fff;
      font-size: 4.5vw;
    }

    .top_img2 {
      width: 5vw;
      height: 5vw;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .middle {
    width: 100%;
    padding: 3vw 0 0 5vw;
    position: relative;

    .robot {
      width: 33vw;
      height: 43vw;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .bth {
      width: 20vw;
      height: 6vw;
      background-image: url("../../assets/img/anniu@2x.png");
      background-size: 100% 100%;
      position: absolute;
      top: 16vw;
      left: 32vw;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 3.2vw;

      img {
        width: 3vw;
        height: 3vw;
        margin-left: 1vw;
      }
    }

    .text {
      // width: 100%;
      color: #fff;
      font-size: 5vw;
      font-weight: 550;
      position: absolute;
      top: 6vw;
      left: 32vw;

      span {
        background: linear-gradient(99deg, #4609F5 0%, #6D1FF3 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .glasses1 {
      width: 20vw;
      height: 20vw;
      position: absolute;
      top: 19vw;
      left: 59vw;

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

    .glasses2 {
      width: 20vw;
      height: 20vw;
      position: absolute;
      top: 25vw;
      left: 75vw;

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }

  .bottom {
    width: 100%;
    padding: 4vw 7vw;
    display: flex;
    justify-content: space-between;

    .left {
      width: 39vw;
      height: 48vw;
      border-radius: 2vw;
      border: #4609F5 1px solid;
      padding: 4vw 3vw 0 3vw;
      background: #030A21;
      position: relative;

      .one {
        font-size: 5vw;
        font-weight: 550;
        color: #fff;
      }

      .two {
        font-size: 5vw;
        font-weight: 550;
        background: linear-gradient(99deg, #4609F5 0%, #6D1FF3 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .img {
        width: 30vw;
        height: 29vw;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }

      .bth {
        width: 16vw;
        height: 6vw;
        background-image: url("../../assets/img/anniu@2x.png");
        background-size: 100% 100%;
        position: absolute;
        top: 2vw;
        left: 28vw;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3.5vw;

        img {
          width: 3vw;
          height: 3vw;
          margin-left: 1vw;
        }
      }
    }

    .right {
      width: 39vw;
      height: 48vw;
      border-radius: 2vw;
      border: #4609F5 1px solid;
      padding: 4vw 3vw 0 3vw;
      background: #030A21;
      position: relative;

      .one {
        font-size: 5vw;
        font-weight: 550;
        color: #fff;
      }

      .two {
        font-size: 5vw;
        font-weight: 550;
        background: linear-gradient(99deg, #4609F5 0%, #6D1FF3 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .img {
        width: 30vw;
        height: 29vw;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }

      .bth {
        width: 16vw;
        height: 6vw;
        background-image: url("../../assets/img/anniu@2x.png");
        background-size: 100% 100%;
        position: absolute;
        top: 2vw;
        left: 28vw;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3.5vw;

        img {
          width: 3vw;
          height: 3vw;
          margin-left: 1vw;
        }
      }
    }
  }

  .gif_box {
    position: relative;
    width: 100%;
    height: 91vw;
    background-image: url("../../assets/img/diqiu.gif");
    background-size: 100% 100%;
    padding-top: 6vw;

    .bth {
      width: 38vw;
      height: 12vw;
      background: linear-gradient(55deg, #4609F5 0%, #6D1FF3 100%);
      box-shadow: 0 1 2vw 0 rgba(243, 112, 255, 0.17);
      border-radius: 1vw;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      font-size: 5vw;
      margin-bottom: 5vw;

      img {
        width: 5vw;
        height: 5vw;
        margin-left: 1vw;
      }
    }

    .rain {
      position: absolute;
      width: 21vw;
      height: 21vw;
      right: 0;
      z-index: 999;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .register {
      position: absolute;
      bottom: 119px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 3vw 4vw;
      background: rgba(41, 41, 41, 0.7);
      color: #fff;
      font-size: 4vw;

      .left {
        display: flex;
        align-items: center;
        font-size: 4vw;

        img {
          width: 5vw;
          height: 5vw;
          margin-right: 1vw;
        }
      }

      p {
        width: 65vw;
      }

      .reigst {
        width: 23vw;
        height: 7vw;
        background: linear-gradient(55deg, #4609F5 0%, #6D1FF3 100%);
        box-shadow: 0 1 2vw 0 rgba(243, 112, 255, 0.17);
        border-radius: 1vw;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3.5vw;

        img {
          width: 3vw;
          height: 3vw;
          margin-left: 1vw;
        }
      }

    }
  }

  .gif {
    position: absolute;
    z-index: 2;
    width: 100%;
    // height: 91vw;
    text-align: center;
    font-size: 5vw;
    color: #fff;
    font-weight: 550;
    background: linear-gradient(180deg, rgb(166, 97, 228) 0%, #fff 10%, #fff 20%, rgb(166, 97, 228) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .gif2 {

    position: relative;
    z-index: 1;
    text-align: center;
    font-size: 5vw;
    color: rgb(104, 29, 239);
    font-weight: 550;
    left: -0.5vw;
    top: -0.7vw;
  }

}
</style>
