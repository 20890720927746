<template>
    <div class="red-packet-page">
        <!-- <red-packet-rain /> -->
        <hRed ref="child"></hRed>
    </div>
</template>

<script>
import hRed from '../../../components/h-red.vue'

export default {
    components: {
        hRed
    },
    data() {
        return {
            info: {},
            id: "",
        }
    },
    created() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.child.onStart(this.id);
        });
    },
    methods: {

    }
}
</script>

<style>
.red-packet-page {
    width: 100%;
    height: 100vh;
}
</style>