<template>
  <div class='indexbox'>
    <nav-bar :title="$t('Details')" left-arrow bg-color="#000" left-color="#ffffff" title-color="#fff" />
    <div v-if="type == 1" class="htmlcontentbox content" v-html="info.content"></div>
    <video id="myVideo" :poster="info.cover" v-if="type == 2" :src="info.video" controls x5-video-player-type="h5-page"
      x5-video-orientation="landscape|portrait" webkit-playsinline="true" playsinline="true">
    </video>
    <!-- <iframe ref="iframe" :src="file_url" frameborder="0"></iframe> -->
    <iframe v-if="type == 3" :src="file_url">
    </iframe>
    <div class="loading" v-if="show">
      <van-loading type="spinner" color="#000" />
    </div>

    <!-- <pdf v-if="type == 3" :src="file_url"></pdf> -->
    <div class="reigst" v-if="con_type == 4" @click="test">
      {{ info.reg_name }}
      <img src="../../../assets/img/more@2x.png" alt="">
    </div>
    <div class="btns" v-if="con_type == 4">
      <div class="bth1" @click="open1()">
        <img src="../../../assets/img/whatsapp.png" alt="">
        WhatsApp
      </div>
      <div class="bth2" @click="open2()">
        <img src="../../../assets/img/teletram.png" alt="">
        Teletram
      </div>
    </div>
    <van-popup v-model="show1" :close-on-click-overlay="false">
      <div class="popup">
        <div class="tops">
          {{ $t('Tips') }}
        </div>
        <div class="Tell">
          {{ info.tips }}
        </div>
        <div class="reigstff" @click="show1 = false">
          {{ $t('CONFIRM') }}
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: "",
      con_type: "",///区域类型1.公司简介区2.项目背景区3.团队福利区4.加入团队区
      type: "",// 1.文章2.视频3.PPT
      info: {},
      file_url: "",
      secret: "",
      timer: "",
      stime: "",
      show: true,
      show1: false,
    }
  },
  methods: {
    test() {
      if (this.info.reg_switch == 1) {
        window.location.href = this.info.reg_url
      } else if (this.info.reg_switch == 0) {
        this.show1 = true
      }
    },
    open1() {
      window.location.href = this.info.whats_url//'whatsapp://';
      // window.location.href = 'tg://';
      // window.location.href = 'twitter://';
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        window.open('https://www.whatsapp.com/download/')
      }, 5000);
      // 跳转app之后禁止再跳转中间页
      document.addEventListener('visibilitychange', () => {
        clearTimeout(this.timer);
      });
    },
    open2() {
      // window.location.href = 'whatsapp://';
      window.location.href = this.info.tele_url//'tg://';
      // window.location.href = 'twitter://';
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        window.open('https://telegram.org/')
      }, 5000);
      // 跳转app之后禁止再跳转中间页
      document.addEventListener('visibilitychange', () => {
        clearTimeout(this.timer);
      });
    },

    urlEncode(str) {
      return encodeURIComponent(str).replace(/'/g, "%27").replace(/"/g, "%22");
    },
    get_info() {
      this.$post({
        url: "/site/detail",
        data: {
          con_type: this.con_type,
          secret: this.secret
        },
        success: (res) => {
          this.info = res.data
          this.type = res.data.type
          this.file_url = 'https://docs.google.com/viewer?url=' + res.data.file + '&embedded=true'
          // this.file_url = 'https://view.officeapps.live.com/op/view.aspx?src=' + this.urlEncode(res.data.file)
          if (this.type == 3) {
            if (this.stime) clearTimeout(this.stime);
            this.stime = setTimeout(() => {
              this.show = false
            }, 6000);
          } else {
            if (this.stime) clearTimeout(this.stime);
            this.stime = setTimeout(() => {
              this.show = false
            }, 1000);
          }

        },
        tip: () => { },
      })
    }
  },
  created() {

    if (this.$route.query.id) {
      this.id = this.$route.query.id
    }
    if (this.$route.query.con_type) {
      this.con_type = this.$route.query.con_type
    }
    if (this.$route.query.secret) {
      this.secret = this.$route.query.secret
    }
    this.get_info()

  },
  mounted() { },
}
</script>
<style lang='less' scoped>
.close {
  padding: 3vw;
  display: flex;
  justify-content: center;
}

.popup {
  width: 73vw;
  border-radius: 2vw;
  border: #641AF3 1px solid;
  background: #030A21;
  padding: 4vw;



  .reigstff {
    width: 27vw;
    padding: 2.5vw 0;
    background: linear-gradient(55deg, #4609F5 0%, #6D1FF3 100%);
    box-shadow: 0 1 2vw 0 rgba(243, 112, 255, 0.17);
    border-radius: 1vw;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4vw;
    margin: 0 auto;
    margin-bottom: 2vw;
  }

  .bottoms {
    color: #661BF3;
    font-size: 3vw;
    text-align: center;
  }

  .tops {
    color: #fff;
    font-size: 4.5vw;
    font-weight: 550;
    text-align: center;
    padding: 0;
    margin-bottom: 5vw;
  }

  .Tell {
    color: #fff;
    font-size: 4vw;
    font-weight: 500;
    text-align: center;
    margin-bottom: 7vw;
  }

  /deep/.van-cell {
    padding: 2vw 3vw;
    border-radius: 1vw;
    background: #52467B;
    margin-bottom: 3vw;

    .van-field__control {
      color: #fff;
    }
  }
}

/deep/.van-popup {
  background: transparent;
}


.loading {
  position: fixed;
  right: 0;
  left: 0;
  top: 50vw;
  margin: 0 auto;
  height: 30vw;
  width: 30vw;
  border-radius: 3vw;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

}

.btns {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 12vw;
}

.reigst {
  width: 40vw;
  height: 12vw;
  background: linear-gradient(55deg, #4609F5 0%, #6D1FF3 100%);
  box-shadow: 0 1 2vw 0 rgba(243, 112, 255, 0.17);
  border-radius: 1vw;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5vw;
  position: fixed;
  bottom: 50vw;
  left: 0;
  right: 0;
  margin: 0 auto;

  img {
    width: 5vw;
    height: 5vw;
    margin-left: 1vw;
  }
}

.bth1 {
  width: 40vw;
  height: 12vw;
  background: rgb(37, 211, 102);
  border-radius: 1vw;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 5vw;
  margin-top: 5vw;

  img {
    width: 10vw;
    height: 10vw;
  }
}

.bth2 {
  width: 40vw;
  height: 12vw;
  background: rgb(35, 160, 221);
  border-radius: 1vw;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 5vw;
  margin-top: 5vw;

  img {
    width: 10vw;
    height: 10vw;
    margin-right: 2vw;
  }
}

iframe {
  width: 100%;
  height: calc(100vh - 11vw);

}

.indexbox {
  width: 100vw;
  min-height: 100vh;
  height: auto;

  .content {
    width: 100%;
    border-radius: 10px;
    padding: 10px 14px;
    color: #fff;
    font-size: 14px;
  }
}

#myVideo {
  width: 100vw;
}
</style>