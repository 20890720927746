<!--
 * @ Author: mahoushaojo
 * @ Create Time: 2021-04-28 10:52:10
 * @ Modified by: yangzhiwei
 * @ Modified time: 2021-05-12 15:53:13
 * @ Description: 抢红包准备阶段倒计时
 -->
<template>
  <div class="content">
    <div class="prepare" :style="{ color: colors }">
      <div class="tips">
        <!-- <img src="~@/assets/img/hongbaoyu/title.png" alt="" /> -->
      </div>
      <img :class="['time', isShow ? 'animate__animated animate__flipInY' : '']" :src="imgList[time - 1]" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      time: 3,
      isShow: true,
      timeout: null,
      imgList: [
        require("@/assets/img/hongbaoyu/one.png"),
        require("@/assets/img/hongbaoyu/two.png"),
        require("@/assets/img/hongbaoyu/three.png"),
      ],
    };
  },
  props: {
    colors: {
      type: String,
      default: "#2FBC78",
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.timeout = setInterval(() => {
        if (this.time > 1) {
          this.isShow = false;
          setTimeout(() => {
            this.isShow = true;
            this.time--;
          }, 100);
        } else {
          /**
           * 倒计时结束
           * *准备就绪
           */
          this.$emit("onReady");
          clearInterval(this.timeout);
        }
      }, 900);
    });
  },
  methods: {

  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

.prepare {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;

  .tips {
    img {
      width: 36vw;
      height: 6vw;
      display: block;
      margin: 0 auto;
    }
  }

  .time {
    width: 18vw;
    height: 18vw;
    display: block;
    margin: 0 auto;
    margin-top: 6vw;
  }
}
</style>