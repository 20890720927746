<template>
    <div class='indexbox'>
        <div class="top">
            <div>
                <van-icon name="arrow-left" color="#fff" size="5vw" @click="goBack" />
            </div>
            <div class="top_img1">
                {{ $t('REAL NAME') }}
            </div>
            <div class="top_img2" @click="change">
                <img v-if="getLang() == 'zh'" src="../../../assets/img/yuyan@2x.png" alt="">
                <img v-else src="../../../assets/img/yindu@2x.png" alt="">
            </div>
        </div>
        <div class="box">
            <div class="card">
                <span>{{ $t('TEL') }}</span>
                <span>{{ info.account }}</span>
            </div>
            <div class="title">
                {{ $t('Verification Code') }}
            </div>
            <van-field v-model="code" type="text" clearable :placeholder="$t('Please enter the verification code')"
                :border="false">
                <template #button>
                    <send-code api="/send/code" :send-tell="{ 'to': info.account }"
                        :send-scene="{ 'scene': 'modify_account' }" :no-token="true"></send-code>
                </template>
            </van-field>
            <div class="title">
                {{ $t('Name') }}
            </div>
            <van-field v-model="name" type="text" clearable :placeholder="$t('Please enter your name')" :border="false">
            </van-field>
            <div class="title">
                {{ $t('Account Number') }}
            </div>
            <van-field v-model="phone" :formatter="formatter" type="text" clearable :placeholder="$t('Please enter your Account Number')"
                :border="false">
            </van-field>
            <div class="title">
                {{ $t('IFSC') }}
            </div>
            <van-field v-model="ifsc" :formatter="formatter" type="text" clearable :placeholder="$t('Please enter your IFSC')" :border="false">
            </van-field>
            <div class="title">
                {{ $t('ID number') }}
            </div>
            <van-field v-model="idcard" :formatter="formatter" type="text" clearable :placeholder="$t('Please enter your ID number')"
                :border="false">
            </van-field>
            <div class="title">
                {{ $t('ID card photo') }}
            </div>
            <van-cell :border="false" center class="up_img_box">
            <template #title>
             
            </template>
            <template #label>
              <div-center>
               <div>
                <uploader :after-read="afterReadFront">
                  <van-image :src="idCardFront" fit="contain" v-if="idCardFront"></van-image>
                  <div class="up_img" v-else>
                    <div class="add">+</div>
                  </div>
                </uploader>
                <div style="color: #fff; width: 100%;text-align: center;margin-top: 3vw;"> {{ $t('front') }} </div>
               </div>
               <div style="width: 4vw;"></div>
               <div>
                <uploader :after-read="afterReadBack">
                  <van-image :src="idCardBack" fit="contain" v-if="idCardBack"></van-image>
                  <div class="up_img" v-else>
                    <div class="add">+</div>
                  </div>
                </uploader>
                <div style="color: #fff; width: 100%;text-align: center;margin-top: 3vw;"> {{ $t('back') }} </div>
            </div>
              </div-center>
            </template>
          </van-cell>


            <div class="bg_color">
                <van-button type="primary" @click="get_sub()" :loading="loading" block>{{ $t('SUBMIT') }}</van-button>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            list: [],
            info: {},
            show1: false,
            code: "",
            name: "",
            phone: "",
            ifsc: "",
            idcard: "",
            loading: false,
            idCardFront: "",
            idCardBack: "",
        }
    },
    created() {
        this.get_info()
    },
    methods: {
     afterReadFront(file) {
        this.loading = true
      // 上传图片
      let formData = new FormData()
      formData.append("file", file.file)
      this.$post({
        url: "/upload/local/image",
        data: formData,
        upload: true,
        success: (res) => {
            setTimeout(() => {
                        this.loading = false
                    }, 1000)
          this.idCardFront = res.data
        },
        tip: () => {
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)
                },
      })
    },
    afterReadBack(file) {
        this.loading = true
      // 上传图片
      let formData = new FormData()
      formData.append("file", file.file)
      this.$post({
        url: "/upload/local/image",
        data: formData,
        upload: true,
        success: (res) => {
            setTimeout(() => {
                        this.loading = false
                    }, 1000)
          this.idCardBack = res.data
        },
        tip: () => {
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)
                },
      })
    },
        formatter(value) {
      // 过滤输入的数字
      return value.replace(/\s+/g, '');
    },
        get_sub() {
            if (!this.code) return this.$toast(this.$t('Please enter the verification code'))
            if (!this.name) return this.$toast(this.$t('Please enter your name'))
            if (!this.phone) return this.$toast(this.$t('Please enter your Account Number'))
            if (!this.ifsc) return this.$toast(this.$t('Please enter your IFSC'))
            if (!this.idcard) return this.$toast(this.$t('Please enter your ID number'))
            if (!this.idCardFront) return this.$toast(this.$t('Please enter your ID card photo'))
            if (!this.idCardBack) return this.$toast(this.$t('Please enter your ID card photo'))


            this.loading = true
            this.$post({
                url: "/user_real/submit",
                loading: true,
                data: {
                    name: this.name,
                    id_card: this.idcard,
                    bank_card: this.phone,
                    sms_code: this.code,
                    ifsc: this.ifsc,
                    idCardFront:this.idCardFront,
                    idCardBack:this.idCardBack,
                },
                success: () => {
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)
                    this.toast({
                        message: this.$t('已提交申请'),
                        onClose: () => {
                            this.$router.go(-1)
                        },
                    })
                },
                tip: () => {
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)
                },
            })
        },
        get_info() {
            this.$get({
                url: "/user/info",
                success: (res) => {
                    this.info = res.data
                },
                tip: () => { },
            })
        },
        change() {
            if (this.getLang() == "zh") {
                this.changeLang("en")
            } else {
                this.changeLang("zh")
            }
        },

    },
}
</script>
<style scoped lang="less">

.up_img_box {
  background: #fff;
  opacity: 1;
  border-radius: 3vw;
  font-size: 4vw;
  color: #000000;
  padding: 4vw;
  margin-bottom: 4vw;
  .van-cell__label {
    margin-top: 2vw;
  }
  .img {
    display: flex;
    justify-content: center;
    opacity: 1;
    border-radius: 3vw;
    overflow: hidden;
  }
  .up_img {
    width: 40vw;
    height: 30vw;
    position: relative;
    overflow: hidden;
    border: #641AF3 1px solid;
    .add {
      line-height: 30vw;
      text-align: center;
      color: #fff;
      font-size: 12vw;
     
    }
  }
  .van-image {
    background: #000;
   height: 30vw;
   width: 40vw;
    display: block;
    /deep/.van-icon {
      font-size: 14vw;
    }
  }
}

/deep/.van-button--mini {
    height: auto !important;
}

/deep/.van-button--primary {
    border: none;
}

/deep/.van-field__control {
    color: #fff;
}

/deep/.van-field__control[type='text']::placeholder {
    color: #52467B !important;
}

/deep/.van-button {
    height: 8vw;
    display: block;
    background: linear-gradient(55deg, #4609F5 0%, #6D1FF3 100%);
}

.bg_color {
    /deep/.van-button {
        color: #fff;
        font-size: 4vw;
        height: 13vw;
        border-radius: 1vw;
        line-height: 13vw;
        text-align: center;
        margin-top: 6vw;
        background: linear-gradient(55deg, #4609F5 0%, #6D1FF3 100%);
    }

}

/deep/.van-field__button {
    height: 8vw !important;
}

.indexbox {
    width: 100vw;
    // height: calc(100vh - 12vw);
    height: 100vh;
    background-image: url("../../../assets/img/bj@2x.png");
    background-size: 100% 100%;

    .top {
        display: flex;
        padding: 3vw 4vw;
        justify-content: space-between;
        align-items: center;

        .top_img1 {
            color: #fff;
            font-size: 4.5vw;
        }

        .top_img2 {
            width: 5vw;
            height: 5vw;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .box {
        padding: 4vw;

        .card {
            position: relative;
            border-radius: 2vw;
            border: #641AF3 1px solid;
            background: #030A21;
            padding: 3.5vw 4vw;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                color: #fff;
                font-size: 4vw;
            }
        }

        .title {
            color: #fff;
            font-size: 4vw;
            padding: 4vw 0;
        }

        /deep/.van-cell {
            border: #641AF3 1px solid;
            background: #030A21;
            border-radius: 2vw;
            padding: 3.5vw 4vw;
        }
    }
}
</style>