<template>
    <div class='indexbox'>
        <page-list class="listbox" api="/credit_log/log?credit=credit1" :offset="0"
            @changelist="(data) => { list = [...list, ...data] }" @refresh="() => { list = [] }">


            <div class="top">
                <div>
                    <van-icon name="arrow-left" color="#fff" size="5vw" @click="goBack" />
                </div>
                <div class="top_img1">
                    {{ $t('BALANCE') }}
                </div>
                <div class="top_img2">
                    <!-- <img @click="change" v-if="getLang() == 'zh'" src="../../../assets/img/yuyan@2x.png" alt="">
                    <img v-else src="../../../assets/img/yindu@2x.png" alt=""> -->
                </div>
            </div>
            <div class="box">
                <div class="card">
                    <div>
                        <div class="card_top">
                            {{ $t('BALANCE') }}
                        </div>
                        <div class="card_bottom">
                            <p><span>₹</span>{{ info.credit1 }}</p>
                        </div>
                    </div>
                    <img src="../../../assets/img/qianbda2x.png" alt="">
                    <div class="reigst" @click="Real_name()">
                        {{ $t('Withdrawal') }}
                    </div>
                </div>
                <div class="title">
                    {{ $t('Balance details') }}
                </div>
                <div class="list">
                    <div class="item" v-for="(item, index) in list" :key="index">
                        <div class="left">
                            <div class="title">{{ item.trend_desc }}</div>
                            <div class="time">{{ item.created_at }}</div>
                        </div>
                        <div class="right">{{ item.type == 1 ? '+' : '-' }}{{ item.num }}</div>
                    </div>
                </div>
            </div>
        </page-list>
        <van-popup v-model="show1" :close-on-click-overlay="false">
            <div class="popup">
                <div class="tops">
                    {{ $t('REAL NAME') }}
                </div>
                <div class="Tell">
                    {{ $t('Not yet registered') }}
                </div>
                <div class="reigstff" @click="go">
                    {{ $t('CONFIRM') }}
                </div>
            </div>
        </van-popup>
        <van-popup v-model="show2" :close-on-click-overlay="false">
            <div class="popup">
                <div class="tops">
                    {{ $t('Withdrawal') }}
                </div>
                <div class="Tell">
                    {{ $t('Withdrawal Amount') }}
                </div>
                <van-field v-model="amount" :border="false" type="digit"
                    :placeholder="$t('Please enter the withdrawal amount')" />
                <div class="reigst">

                    <van-button type="primary" :loading="loading" @click="submit()" block> {{ $t('CONFIRM')
                        }}</van-button>
                </div>
            </div>
            <div class="close">
                <van-icon name="close" color="#fff" size="7vw" @click="show2 = false" />
            </div>
        </van-popup>
    </div>
</template>
<script>
export default {
    data() {
        return {
            list: [],
            info: {},
            show1: false,
            show2: false,
            amount: "",
            loading: false
        }
    },
    created() {
        this.get_info()
    },
    methods: {
        submit() {
            if (!this.amount) return this.$toast(this.$t('Please enter the withdrawal amount'))
            this.loading = true
            this.$post({
                url: "/withdraw/submit",
                loading: true,
                data: {
                    amount: this.amount,
                },
                success: () => {
                    setTimeout(() => {
                        this.loading = false
                    }, 2000)
                    this.amount = ""
                    this.toast({
                        message: this.$t('已提交申请'),
                        onClose: () => {
                            this.show2 = false
                        },
                    })
                },
                tip: () => {
                    setTimeout(() => {
                        this.loading = false
                    }, 2000)
                },
            })
        },
        get_info() {
            this.$get({
                url: "/user/info",
                success: (res) => {
                    this.info = res.data
                },
                tip: () => { },
            })
        },
        Real_name() {
            if (this.info.real_status == 0 || this.info.real_status == 3) {
                this.show1 = true
            } else {
                this.show2 = true
            }
        },
        go() {
            this.router('Real_name')
            this.show1 = false
        },
        change() {
            if (this.getLang() == "zh") {
                this.changeLang("en")
            } else {
                this.changeLang("zh")
            }
        },

    },
}
</script>
<style scoped lang="less">
.close {
    padding: 3vw;
    display: flex;
    justify-content: center;
}

.popup {
    width: 73vw;
    border-radius: 2vw;
    border: #641AF3 1px solid;
    background: #030A21;
    padding: 4vw;

    .reigst {

        /deep/.van-button--primary {
            border: none;
        }

        /deep/.van-button {
            width: 27vw;
            padding: 2.5vw 0;
            background: linear-gradient(55deg, #4609F5 0%, #6D1FF3 100%);
            box-shadow: 0 1 2vw 0 rgba(243, 112, 255, 0.17);
            border-radius: 1vw;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 4vw;
            margin: 0 auto;
            margin-bottom: 3vw;
        }
    }

    .reigstff {
        width: 27vw;
        padding: 2.5vw 0;
        background: linear-gradient(55deg, #4609F5 0%, #6D1FF3 100%);
        box-shadow: 0 1 2vw 0 rgba(243, 112, 255, 0.17);
        border-radius: 1vw;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 4vw;
        margin: 0 auto;
        margin-bottom: 2vw;
    }

    .bottoms {
        color: #661BF3;
        font-size: 3vw;
        text-align: center;
    }

    .tops {
        color: #fff;
        font-size: 4.5vw;
        font-weight: 550;
        text-align: center;
        padding: 0;
        margin-bottom: 5vw;
    }

    .Tell {
        color: #fff;
        font-size: 4vw;
        font-weight: 500;
        // text-align: center;
        margin-bottom: 2vw;
    }

    /deep/.van-cell {
        padding: 2vw 3vw;
        border-radius: 1vw;
        background: #52467B;
        margin-bottom: 6vw;

        .van-field__control {
            color: #fff;
        }
    }
}

/deep/.van-popup {
    background: transparent;
}

.indexbox {
    width: 100vw;
    // height: calc(100vh - 12vw);
    height: 100vh;
    background-image: url("../../../assets/img/bj@2x.png");
    background-size: 100% 100%;

    .top {
        display: flex;
        padding: 3vw 4vw;
        justify-content: space-between;
        align-items: center;

        .top_img1 {
            color: #fff;
            font-size: 4.5vw;
        }

        .top_img2 {
            width: 5vw;
            height: 5vw;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .box {
        padding: 4vw;

        .card {
            position: relative;
            border-radius: 2vw;
            border: #641AF3 1px solid;
            background: #030A21;
            padding: 2vw 4vw;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .reigst {
                width: 27vw;
                height: 10vw;
                line-height: 10vw;
                background: linear-gradient(55deg, #4609F5 0%, #6D1FF3 100%);
                box-shadow: 0 1 2vw 0 rgba(243, 112, 255, 0.17);
                border-radius: 1vw;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 4vw;
                margin: 0 auto;
                position: absolute;
                right: 4vw;
                bottom: 6vw;
            }

            .card_top {
                font-size: 4.5vw;
                color: #52467B;
                margin-bottom: 5vw;
            }

            .card_bottom {
                p {
                    span {
                        color: #fff;
                        font-size: 5vw;
                        font-weight: 500;
                    }

                    color: #fff;
                    font-size: 6.5vw;
                    font-weight: 550;
                }
            }

            img {
                width: 25vw;
                height: 30vw;
            }
        }

        .title {
            color: #fff;
            font-size: 4.5vw;
            padding: 4vw 0;
        }

        .list {
            border-radius: 2vw;
            border: #641AF3 1px solid;
            background: #030A21;
            padding: 0 4vw;

            .item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 3vw 0;
                border-bottom: #221A3E 1px solid;

                .right {
                    color: #fff;
                    font-size: 4.5vw;
                    font-weight: 550;
                }

                .left {
                    .title {
                        color: #fff;
                        font-size: 4vw;
                        margin-bottom: 2vw;
                        padding: 0;
                    }

                    .time {
                        color: #52467B;
                        font-size: 4vw;

                    }
                }
            }

        }
    }
}
</style>