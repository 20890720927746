<template>
  <div :class="{ 'van-nav-bar__placeholder': fixed&&placeholder }" :style="font_color">
    <van-nav-bar :title="title" :left-text="leftText" :right-text="rightText" :left-arrow="leftArrow" :border="border" :fixed="fixed" safe-area-inset-top :z-index="zIndex"
      @click-left.prevent="clickLeft" @click-right.prevent="clickRight" :style="set_bg_style()" :class="{ 'font-color': leftColor || titleColor || rightColor }">
      <template #left>
        <slot name="left" />
      </template>
      <template #title>
        <slot name="title" />
      </template>
      <template #right>
        <slot name="right" />
      </template>
    </van-nav-bar>
  </div>
</template>
<script>
// https://vant-contrib.gitee.io/vant/v2/#/zh-CN/nav-bar#api
export default {
  props: {
    title: {
      type: String,
      default: "标题"
    },
    leftText: {
      type: String,
      default: ""
    },
    rightText: {
      type: String,
      default: ""
    },
    leftArrow: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: Boolean,
      default: true,
    },
    zIndex: {
      type: [Number, String],
      default: 100,
    },
    //导航栏背景色(可传值修改)
    bgColor: {
      type: String,
      default: ""
    },
    //左侧 标题 右侧字体色少部分样式可传值修改
    leftColor: {
      type: String,
      default: ""
    },
    titleColor: {
      type: String,
      default: ""
    },
    rightColor: {
      type: String,
      default: ""
    },
  },
  data() {
    return {}
  },
  computed: {
    font_color() {
      return {
        '--left-color': this.leftColor,
        '--title-color': this.titleColor,
        '--right-color': this.rightColor,
      }
    }
  },
  methods: {
    clickLeft() {
      this.goBack()
    },
    clickRight() {
      this.$emit('click-right')
    },
    set_bg_style() {
      if (this.bgColor) {
        return `background:${this.bgColor}`
      }
    },
  },
}
</script>
<style lang='less' scoped>
// 左侧 标题 右侧字体色绝大部分样式默认色
@nav_bg_color: #fff; //导航栏背景色
@nav_fo_color: #000; //导航栏字色
// 高度占位
.van-nav-bar__placeholder {
  width: 100%;
  height: 46.13px;
}
/deep/.van-nav-bar {
  background: @nav_bg_color;
  .van-nav-bar__left {
    .van-icon {
      color: @nav_fo_color;
      font-size: 18px;
      font-weight: 600;
    }
    .van-nav-bar__text {
      color: @nav_fo_color;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .van-nav-bar__title {
    color: @nav_fo_color;
    font-size: 18px;
    font-weight: 550;
  }
  .van-nav-bar__right {
    .van-nav-bar__text {
      font-size: 14px;
      color: @nav_fo_color;
      font-weight: 550;
    }
  }
}
/deep/.van-nav-bar.font-color {
  .van-nav-bar__left {
    .van-icon {
      color: var(--left-color);
    }
    .van-nav-bar__text {
      color: var(--left-color);
    }
  }
  .van-nav-bar__title {
    color: var(--title-color);
  }
  .van-nav-bar__text {
    color: var(--right-color);
  }
}
</style>